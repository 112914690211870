
import { defineComponent, ref } from 'vue';
import QuoteViewer from "@/components/QuoteViewer.vue";

export default defineComponent({
  name: 'App',
  components: {
    QuoteViewer,
  },
  setup() {
    const count = ref(2);
    return {
      count
    }
  },
});
