
import { defineComponent, onMounted, ref } from 'vue';
export default defineComponent({
  name: "QuoteViewer",
  async setup() {
    const text = ref(["Loading..."]);
    async function getQuotes() {
      // Axios?
      const response = await fetch('https://feathers.exposed/api/quotes');
      const jsonResponse = await response.json();
      return (jsonResponse as Array<any>).map(e => { return `#${e.ID}: "${e.Quote}" -- ${e.GameName}` });
    }
    onMounted(async () => {
      text.value = await getQuotes();
    });
    return {
      text
    }
  }
});
